<template>
  <div class="container">
    <div
      class="min-vh-100 d-flex flex-column align-items-center bg-light th-pt-80"
    >
      <h2 class="text-center">
        Jūs sėkmingai užpildėte „TermsHub“ verslo įmonės GDPR klausimyną
      </h2>
      <p class="body1 text-center">
        Mes peržiūrėsime jūsų duomenis, paruošime reikiamus dokumentus ir
        atsakysime kiek įmanoma greičiau. Tačiau tai gali užtrukti iki 7 dienų
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicQuestionnaireThankYou",
  mounted() {
    //Logout
    this.$store.commit("SET_TOKEN", null);
    this.$store.commit("SET_USER_ID", null);

    window?.localStorage?.removeItem(process.env.VUE_APP_STORAGE_NAME);
  },
};
</script>

<style lang="scss" scoped></style>
